import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layouts";
import Seo from "../components/seo";
import {	BreadCrumb } from '../components/Section'
import GrayWoodSection from '../components/Section/GrayWoodSection';
import Hero from "../sections/Financing/Hero";
import BenefitSection from "../sections/Financing/BenefitSection";
import ProcessSection from "../sections/Financing/ProcessSection";
import PartnersSection from "../sections/Financing/PartnersSection";
import CtaSection from '../sections/CtaSection';
import Content, { HTMLContent } from '../components/Content';

import generateHTML from '../utils/generateHTML';

const FinancingPage = ({ data, location }) => {
	const pageData = data.contentfulFinancingPage
	const heroData = {
		title: pageData.heroTitle,
		description: pageData.heroDescription.heroDescription,
		image: pageData.heroImage
	}
	const benefitData ={
		title: pageData.benefitsTitle,
		description: pageData.benefitsDescription.benefitsDescription,
		benefits: pageData.benefits
	}
	const formData = {
		title: pageData.formTitle,
		description: pageData.formDescription.formDescription,
		location: location
	}
	const processData = {
		label: pageData.processLabel,
		title: pageData.processSectionTitle,
		description: pageData.processSectionDescription.processSectionDescription,
		process: pageData.process
	}
	
	const PostContent = HTMLContent || Content;
	return(
		<Layout footerCta location={location}>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Financing</span>
				</div>
			</BreadCrumb>
			<Hero data={heroData} partners={pageData.partners} />
			<BenefitSection data={benefitData} formData={formData} />
			<ProcessSection data={processData} />
			<PartnersSection data={pageData.partnerSection} />
			<GrayWoodSection maxWidth="900px">
				<div className="container">
					<PostContent content={generateHTML(pageData.content.childMarkdownRemark.html)} />
				</div>
			</GrayWoodSection>
			<CtaSection />
		</Layout>
	)
}

export default FinancingPage;

export const query = graphql`
  query FinancingPageQuery {
  	contentfulFinancingPage {
  		metaTitle
  		metaDescription
  		heroTitle
	    heroDescription {
	      heroDescription
	    }
	    heroImage {
	      gatsbyImageData
	    }
	    benefitsTitle
	    benefitsDescription {
	      benefitsDescription
	    }
	    benefits {
	      title
	      description
	    }
	    formTitle
	    formDescription {
	      formDescription
	    }
	    processLabel
      processSectionTitle
      processSectionDescription {
        processSectionDescription
      }
      process {
        step
        description
      }
	    content {
        childMarkdownRemark {
          html
        }
      }
      partners {
	      title
	      description
	      gatsbyImageData(quality: 80)
	    }
	    partnerSection {
	      title
	      description {
	        description
	      }
	      features {
	        title
	        image {
	          gatsbyImageData(quality: 80)
	        }
	        description {
	          childMarkdownRemark {
	            html
	          }
	        }
	      }
	    }
  	}
  }
`