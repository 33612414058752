import React, { useContext } from "react";
import styled from "styled-components";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

import {
  Section,
  SectionBgWrap,
  SectionTitle,
  SectionDescription,
} from "../../components/Section";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LineArrowRight from "../../components/Icons/LineArrowRight";

import { Context } from "../../layouts/context";

const PartnersWrap = styled.div`
  .card {
    position: relative;
    max-width: 600px;
    margin: auto;
    padding: 34px 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;

    .h4 {
      color: #fff;
    }

    .description {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 35px 10px;
      width: 100%;
      max-width: 450px;
      margin: auto;
      text-align: left;
      color: white;
    }
    .button-wrap {
      position: absolute;
      width: calc(100% - 60px);
      bottom: -15px;
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    .card {
      padding: 34px 15px;
    }
  }
`;

const PartnersSection = ({ data }) => {
  const { setPopupContext } = useContext(Context);

  return (
    <Section pt="80px" pb="80px">
      <SectionBgWrap>
        <StaticImage
          src="../../images/partners-section-bg.png"
          placeholder="blurred"
          quality={95}
          alt="review hero"
        />
      </SectionBgWrap>
      <div className="container">
        <SectionTitle color="#fff" maxWidth="800px">
          {data.title}
        </SectionTitle>
        <SectionDescription color="#fff" maxWidth="800px">
          {data.description.description}
        </SectionDescription>
        <PartnersWrap>
          <div className="card">
            <GatsbyImage
              image={data.features[0].image.gatsbyImageData}
              alt="Buildings And More"
            />
            <div className="h4">{data.features[0].title}</div>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: data.features[0].description.childMarkdownRemark.html,
              }}
            />
            <div
              className="button-wrap"
              onClick={() => setPopupContext("block")}
              aria-hidden="true"
            >
              <PrimaryButton text="Get Started Now" icon={<LineArrowRight />} />
            </div>
          </div>
        </PartnersWrap>
      </div>
    </Section>
  );
};

export default PartnersSection;
